<template>
  <div>
    <div class="page-content-search">
      <el-form
        ref="form"
        :model="search"
        size="mini"
        :label-width="this.env.search_label_width"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="用户名称">
              <el-input v-model="search.user_nick"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="用户手机">
              <el-input v-model="search.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发送状态">
              <el-select
                v-model="search.state"
                placeholder="请选择类型"
                style="width: 100%"
              >
                <el-option label="全部" value="all"></el-option>
                <el-option label="发送成功" :value="1"></el-option>
                <el-option label="待发送" :value="0"></el-option>
                <el-option label="发送失败" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button
                icon="el-icon-search"
                size="mini"
                type="primary"
                @click="is_search"
                >搜索
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-table
      size="medium"
      border
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column prop="user_nick" label="用户名称" />
      <el-table-column prop="phone" label="用户手机号" width="140" />
      <el-table-column prop="create_time" label="发送时间" width="140" />
      <el-table-column prop="state" label="发送状态">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.state === 0">待发送</el-tag>
          <el-tag size="mini" type="success" v-else-if="scope.row.state === 1"
            >发送成功</el-tag
          >
          <el-tag size="mini" type="info" v-else-if="scope.row.state === 2"
            >发送失败</el-tag
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 20px;"></div>
    <el-pagination
      @current-change="getlist"
      :page-size="this.env.pageSize"
      :pager-count="7"
      background
      layout="prev, pager, next, total"
      :current-page.sync="page"
      :total="count"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "OrderList",
  data() {
    return {
      id: 0,
      loading: false, // 加载状态
      page: 1, // 当前页数
      count: 0, // 数据总条数
      tableData: [], // 添加页面数据
      search: {}, // 搜索内容
    };
  },
  // 创建
  created() {},
  methods: {
    init(id) {
      this.id = id;
      this.count = 0;
      this.tableData = [];
      this.search = {
        user_nick: "",
        phone: "",
      };
      this.is_search();
    },
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 获取可选教练列表
    getlist() {
      let postdata = {
        api_name: "wxmp.template.getsendmsglist",
        token: this.Tool.get_l_cache("token"),
        msg_id: this.id,
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.total;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
  },
};
</script>

<style scoped></style>
