<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>消息模板列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="创建时间">
                <el-date-picker
                  style="width: 100%"
                  v-model="searchtime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button
                  icon="el-icon-circle-close"
                  size="medium"
                  @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
            <el-button
              v-if="is_auth('wxmp.template.sendtemplate')"
              icon="el-icon-plus"
              size="medium"
              type="primary"
              @click="goToSend()"
              >发送
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
      <!--列表-->
      <el-table
        size="medium"
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="template_id" label="模板id" width="400" />
        <el-table-column prop="template_id" label="发送内容" width="500">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-tickets"
              @click="see_content(scope.row)"
              >查看内容
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="发送时间" width="200" />
        <el-table-column prop="name" label="操作人" width="150" />
        <el-table-column prop="sum" label="接收人数量" width="180" />

        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              v-if="is_auth('wxmp.template.getsendmsglist')"
              @click="tosee(scope.row.msg_id)"
              size="mini"
              >查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px;"></div>
      <!--分页-->
      <el-pagination
        @current-change="getData"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total, jumper"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
    </div>
    <!--订单内容-->
    <el-dialog
      v-loading="loading"
      title="发送用户信息"
      :visible.sync="user_dialog"
      width="70%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-row>
        <TemplateUserList ref="templateUserListRef" />
      </el-row>
    </el-dialog>
    <!--笔记内容-->
    <el-dialog
      title="模板信息内容"
      :visible.sync="see_content_page"
      width="40%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      ><div v-if="see_content_content.send_data">
        <section>
          <span style="font-weight:bolder">服务标题：</span>
          <span>{{ see_content_content.send_data.first_value }}</span>
        </section>
        <section style="margin-top:10px">
          <span style="font-weight:bolder">服务名称：</span>
          <span>{{ see_content_content.send_data.data_name }}</span>
        </section>
        <section style="margin-top:10px">
          <span style="font-weight:bolder">生效时间：</span>
          <span>
            {{ see_content_content.send_data.data_time }}
          </span>
        </section>
        <section style="margin-top:10px">
          <span style="font-weight:bolder">服务内容：</span>
          <span>{{ see_content_content.send_data.data_text }}</span>
        </section>
        <section style="margin-top:10px">
          <span style="font-weight:bolder">备注：</span>
          <span>{{ see_content_content.send_data.remark_value }}</span>
        </section>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TemplateUserList from "./page/TemplateUserList.vue";
export default {
  components: {
    TemplateUserList,
  },
  data() {
    return {
      page_name: "消息模板列表",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      searchtime: null, // 时间条件
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      userListData: [],
      userListCount: 0,
      settagPage: false,
      user_dialog: false,
      see_content_page: false,
      see_content_content: {},
      // 标签数据
      tagdata: {
        store_name: "",
        store_tag: [],
      },
      tag_list: [], // 标签列表
      select_tag: "", // 选中的标签

      visitsVisible: false,
      info: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.search = {
        store_name: "",
        responsible: "",
        store_phone: "",
        city: "",
        publish_state: "",
      };
      this.searchtime = null;
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getData();
    },
    // 获取列表
    getData() {
      let postdata = {
        api_name: "wxmp.template.getsendmsg",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.start_time = this.searchtime[0];
        postdata.end_time = this.searchtime[1];
      }
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    tosee(id) {
      this.user_dialog = true;
      setTimeout(() => {
        this.$refs.templateUserListRef.init(id);
      }, 300);
    },
    // 查看模板发送内容
    see_content(content) {
      this.see_content_page = true;
      this.see_content_content = content;
    },
    // 前往发送模板页面
    goToSend() {
      this.$router.push({ path: "/mpwx/templatemsgsend" });
    },
  },
};
</script>
